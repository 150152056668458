<script setup lang="ts">
import SurfaceApp from '@@/vuecomponents/SurfaceApp.vue'
import { nextTick, onBeforeMount, ref } from 'vue'

const isReadyToMount = ref<boolean>(false)

onBeforeMount(async () => {
  // We need an nextTick here to let the pinia and vuex store finish intializing
  // to prevent a race condition where the SurfaceApp mounts but certain states are not yet available
  await nextTick()
  isReadyToMount.value = true
})
</script>

<template>
  <SurfaceApp v-if="isReadyToMount" />
</template>
