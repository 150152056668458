// @file Composable to monitor drag and dro events from external sources.
import device from '@@/bits/device'
import { requestIdleCallback } from '@@/bits/request_idle_callback'
import { useComposerModalAlertStore } from '@@/pinia/composer_modal_alert'
import { useSurfaceCommentAttachmentsStore } from '@@/pinia/surface_comment_attachments'
import { useSurfaceContentPickerStore } from '@@/pinia/surface_content_picker'
import { DropZone, useSurfaceDragAndDropEventsStore } from '@@/pinia/surface_drag_and_drop_events_store'
import { useSurfaceFilterStore } from '@@/pinia/surface_filter'
import { useSurfaceMapStore } from '@@/pinia/surface_map'
import { useSurfacePostsDragAndDropStore } from '@@/pinia/surface_posts_drag_and_drop'
import { useSurfacePostActionStore } from '@@/pinia/surface_post_action'
import { useSurfaceSectionsStore } from '@@/pinia/surface_sections'
import { useWindowSizeStore } from '@@/pinia/window_size'
import type { ContainsSource } from '@atlaskit/pragmatic-drag-and-drop/dist/types/public-utils/external/native-types'
import { monitorForExternal, type ExternalEventPayloadMap } from '@atlaskit/pragmatic-drag-and-drop/external/adapter'
import { containsFiles } from '@atlaskit/pragmatic-drag-and-drop/external/file'
import { containsHTML } from '@atlaskit/pragmatic-drag-and-drop/external/html'
import { containsText } from '@atlaskit/pragmatic-drag-and-drop/external/text'
import { containsURLs } from '@atlaskit/pragmatic-drag-and-drop/external/url'
import { preventUnhandled } from '@atlaskit/pragmatic-drag-and-drop/prevent-unhandled'
import type { CleanupFn } from '@atlaskit/pragmatic-drag-and-drop/types'
import { createSharedComposable } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { onBeforeUnmount, onMounted } from 'vue'

/**
 * Returns a function that checks if the user can drop files/links/text to a padlet.
 */
const useCanHandleDropFromExternal = createSharedComposable(() => {
  const { isSmallerThanTabletPortrait } = storeToRefs(useWindowSizeStore())
  const { isAPostDragging } = storeToRefs(useSurfacePostsDragAndDropStore())
  const { isASectionDragging } = storeToRefs(useSurfaceSectionsStore())
  const { isDraggingLocationPickerPin, isDraggingPosts: isDraggingPostInMap } = storeToRefs(useSurfaceMapStore())

  const canHandle = (e: ContainsSource): boolean => {
    return (
      !device.app && // Disable for mobile app.
      !isSmallerThanTabletPortrait.value && // Only enable for devices with tablet portrait plus screen size.
      (containsFiles(e) || containsURLs(e) || containsText(e)) && // Can be a file, a link or text.
      !containsHTML(e) && // Can't be HTML.
      !isAPostDragging.value &&
      !isASectionDragging.value && // Disable when dragging a post/section.
      !(isDraggingLocationPickerPin.value || isDraggingPostInMap.value) // Disable when dragging a location picker pin or post in Map.
    )
  }

  return { canHandle }
})

/**
 * Monitors the document for drag and drop events and sets the handling state in the store.
 *
 * IMPORTANT: This composable should be used only once in the app.
 */
const useDragAndDropFromExternalMonitor = (): void => {
  const { startHandlingDragEvent, stopHandlingDragEvent, setActiveDropZone } = useSurfaceDragAndDropEventsStore()
  const { canHandle } = useCanHandleDropFromExternal()

  const { resetComposerAlert } = useComposerModalAlertStore()
  const { hidePostContentPicker } = useSurfaceContentPickerStore()
  const { hideCommentContentPicker } = useSurfaceCommentAttachmentsStore()
  const { hidePostActionMenu } = useSurfacePostActionStore()
  const { hideSectionActionMenu } = useSurfaceSectionsStore()
  const { xFilterModal } = storeToRefs(useSurfaceFilterStore())

  /**
   * Close panels/modals that might obstruct the drop target.
   */
  const closeModalsAndMenusOnDragStart = (): void => {
    resetComposerAlert()
    hidePostContentPicker()
    hideCommentContentPicker()
    hidePostActionMenu()
    hideSectionActionMenu()
    xFilterModal.value = false
  }
  /**
   * Returns the drop zone where the user is currently dragging.
   * Other than `document.body`, valid drop zones must be defined with a `data-drop-zone-name` attribute
   * whose value is one of the `DropZone` enum values.
   */
  const detectDropZone = (e: ExternalEventPayloadMap['onDragStart']): DropZone | null => {
    const target = e.location.current.dropTargets[0]
    if (target == null) return null
    if (target.element === document.body) return DropZone.DOCUMENT
    const dropZoneName = (target.element as HTMLElement).dataset.dropZoneName
    if (dropZoneName === DropZone.POST_COMPOSER) return DropZone.POST_COMPOSER
    if (dropZoneName === DropZone.EXPANDED_VIEW) return DropZone.EXPANDED_VIEW
    if (dropZoneName === DropZone.POST_COMMENT_SECTION) return DropZone.POST_COMMENT_SECTION
    return null
  }

  let cleanUp: CleanupFn

  onMounted(() => {
    requestIdleCallback(() => {
      cleanUp = monitorForExternal({
        canMonitor: canHandle,
        onDragStart: (e) => {
          closeModalsAndMenusOnDragStart()
          startHandlingDragEvent()
          setActiveDropZone(detectDropZone(e))
          // In case no drop targets handle the drop event,
          // this will prevent the browser from opening the entity
          // in a new tab.
          preventUnhandled.start()
        },
        onDropTargetChange: (e) => {
          setActiveDropZone(detectDropZone(e))
        },
        onDrop: () => {
          stopHandlingDragEvent()
          setActiveDropZone(null)
          preventUnhandled.stop()
        },
      })
    })
  })

  onBeforeUnmount(() => {
    cleanUp?.()
  })
}

export { useCanHandleDropFromExternal, useDragAndDropFromExternalMonitor }
