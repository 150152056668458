<script setup lang="ts">
import { setCookie } from '@@/bits/cookie'
import { dir } from '@@/bits/current_dir'
import device from '@@/bits/device'
import { isAppUsing, isDebugMode } from '@@/bits/flip'
import { __ } from '@@/bits/intl'
import { getSearchParam } from '@@/bits/location'
import { observeSizeChange } from '@@/bits/resize_observer'
import { defineAsyncComponent } from '@@/bits/vue'
import { initializeZoomSdk } from '@@/bits/zoom'
import { useCommandsStore } from '@@/pinia/commands'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceAIChatStore } from '@@/pinia/surface_ai_chat_store'
import { useSurfaceGradingPanelStore } from '@@/pinia/surface_grading_panel'
import { useSurfaceGuestStore } from '@@/pinia/surface_guest_store'
import { useSurfaceOnboardingDemoPadletPanelStore } from '@@/pinia/surface_onboarding_demo_padlet_panel_store'
import { useSurfaceOnboardingPanelStore } from '@@/pinia/surface_onboarding_panel'
import { useSurfacePermissionsStore } from '@@/pinia/surface_permissions'
import { useWindowSizeStore } from '@@/pinia/window_size'
import GlobalMentionAutocomplete from '@@/vuecomponents/GlobalMentionAutocomplete.vue'
import GlobalSnackbar from '@@/vuecomponents/GlobalSnackbar.vue'
import SkipToContentLink from '@@/vuecomponents/SkipToContentLink.vue'
import SurfaceOffline from '@@/vuecomponents/SurfaceOffline.vue'
import { useFullscreen } from '@@/vuecomposables/fullscreen'
import { useSurfacePageStyling } from '@@/vuecomposables/surface_page_styling'
import { useDragAndDropFromExternalMonitor } from '@@/vuecomposables/useDragAndDropFromExternalMonitor'
import { useSurfacePresence } from '@@/vuecomposables/useSurfacePresence'
import { processedUrl } from '@padlet/vivaldi-client'
import { storeToRefs } from 'pinia'
import tinykeys from 'tinykeys'
import type { ComponentPublicInstance } from 'vue'
import { computed, nextTick, onBeforeMount, onBeforeUnmount, onMounted, ref, watch } from 'vue'

const SurfaceContainer = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceContainer.vue'))
const WhiteboardContainer = defineAsyncComponent(() => import('@@/vuecomponents/WhiteboardContainer.vue'))
const SurfaceDragAndDropOverlay = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceDragAndDropOverlay.vue'))
const SurfaceSlideshow = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceSlideshow.vue'))
const CommandDialog = defineAsyncComponent(() => import('@@/vuecomponents/CommandDialog.vue'))
const SurfaceExportsDocumentStatus = defineAsyncComponent(
  () => import('@@/vuecomponents/SurfaceExportsDocumentStatus.vue'),
)
const SurfaceOnboardingPanel = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceOnboardingPanel.vue'))
const SurfaceOnboardingPanelMobile = defineAsyncComponent(
  () => import('@@/vuecomponents/SurfaceOnboardingPanelMobile.vue'),
)
const SurfaceGradingPanel = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceGradingPanel.vue'))
const SurfaceGradingPanelMobile = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceGradingPanelMobile.vue'))
const SurfaceChangeGuestModal = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceChangeGuestModal.vue'))
const SurfaceTransferWallModal = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceTransferWallModal.vue'))
const SurfaceGuestIdModal = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceGuestIdModal.vue'))
const SurfaceGuestIdDrawer = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceGuestIdDrawer.vue'))
const SurfaceAIChatPanel = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceAIChatPanel.vue'))
const SurfaceOnboardingDemoPadletLanding = defineAsyncComponent(
  () => import('@@/vuecomponents/SurfaceOnboardingDemoPadletLanding.vue'),
)

useDragAndDropFromExternalMonitor()
useSurfacePresence()
useFullscreen()
useSurfacePageStyling()

const commandsStore = useCommandsStore()

const { isSmallerThanTabletPortrait, isSmallerThanDesktop } = storeToRefs(useWindowSizeStore())
const {
  isOnline,
  isWhiteboard,
  isSlideshow,
  isGeneratingPDF,
  hasSidePanelOutsideSurface,
  isSidePanelRounded,
  xTransferWallDialog,
  wallAttributes,
} = storeToRefs(useSurfaceStore())
const { xOnboardingPanel } = storeToRefs(useSurfaceOnboardingPanelStore())
const { isDemoPadletPanel, isDemoPadletPanelDesktop, isLandingStep } = storeToRefs(
  useSurfaceOnboardingDemoPadletPanelStore(),
)
const { xSurfaceGradingPanel } = storeToRefs(useSurfaceGradingPanelStore())
const { xSurfaceAIChatPanel } = storeToRefs(useSurfaceAIChatStore())
const { xGuestIdCard, xGuestIdModal, xChangeGuestModal, shouldEnableAnonymousAttribution } = storeToRefs(
  useSurfaceGuestStore(),
)
const { xCommandDialog } = storeToRefs(commandsStore)
const { amIRegistered, canIPost } = storeToRefs(useSurfacePermissionsStore())

const isDemoPadletLandingStep = computed(() => {
  return isDemoPadletPanelDesktop.value && isLandingStep.value
})

// #region Mobile side panel height
const surfaceMobileSidePanelHeight = ref('0px')
const surfaceMobileSidePanel = ref<ComponentPublicInstance>()

const handleMobileSidePanelMounted = (): void => {
  const htmlElement = surfaceMobileSidePanel.value?.$el as HTMLElement | undefined
  if (htmlElement === undefined) return
  surfaceMobileSidePanelHeight.value = `${htmlElement.clientHeight}px`
  observeSizeChange(htmlElement, () => {
    if (htmlElement === undefined) return
    surfaceMobileSidePanelHeight.value = `${htmlElement.clientHeight}px`
  })
}

// TODO: handle when mobile side panel is unmounted
// #endregion

// #region Zoom app
// TODO: Add type for zoomSdk
const configureZoomApp = async (): Promise<any> => {
  const zoomSdk = (await import('@zoom/appssdk')).default
  await initializeZoomSdk()
  return zoomSdk
}
// #endregion

// #region Anonymous attribution
const ANONYMOUS_USER_HEARTBEAT_MAX_AGE_COOKIE_IN_SECONDS = 15
let anonymousUserHeartbeatInterval: ReturnType<typeof setInterval> | null = null

const setAnonymousUserHeartbeatCookie = (): void => {
  if (anonymousUserHeartbeatInterval !== null) {
    clearInterval(anonymousUserHeartbeatInterval)
  }
  anonymousUserHeartbeatInterval = setInterval(() => {
    // Periodically set the ww_auh cookie with Max-Age of 15 seconds to expire the session if all tabs are closed after 15 seconds
    // Refer to Powwow::ANONYMOUS_USER_HEARTBEAT_COOKIE
    setCookie('ww_auh', String(new Date().getTime()), {
      maxAgeInSeconds: ANONYMOUS_USER_HEARTBEAT_MAX_AGE_COOKIE_IN_SECONDS,
    })
  }, ANONYMOUS_USER_HEARTBEAT_MAX_AGE_COOKIE_IN_SECONDS * 1000)
}

watch(amIRegistered, (value) => {
  if (shouldEnableAnonymousAttribution.value && value === false) {
    setAnonymousUserHeartbeatCookie()
  }
})

// #endregion

// #region Full-client surface
const updateTitle = (): void => {
  const wallTitle = wallAttributes.value?.title ?? __('Padlet') // Fallback
  if (isSlideshow.value) {
    document.title = __('Slideshow | %{wallTitle}', { wallTitle })
    return
  }
  if (isGeneratingPDF.value) {
    document.title = __('Generating PDF...')
    return
  }
  document.title = wallTitle
}

const updatePageBackground = (): void => {
  const wallBackground = wallAttributes.value?.background
  if (wallBackground == null) return
  const effect = wallBackground.effect === 'blur' ? 'blur:10' : undefined
  const imageUrl = processedUrl(wallBackground.url, {
    width: wallBackground.width,
    height: wallBackground.height,
    effect,
  })
  const image = new Image()
  image.src = imageUrl
  image.onload = () => {
    const bodyTag = document.body
    bodyTag.style.backgroundSize = wallBackground.fill === 'cover' ? 'cover' : 'auto'
    bodyTag.style.backgroundImage = `url('${imageUrl}')`
    // We unset the html root element's background image since
    // in full_client_surface/show.html.erb, we set it due to the html body not being rendered yet
    const htmlTag = document.documentElement
    htmlTag.style.backgroundImage = ''
  }
}

const initializeFavicon = (): void => {
  const fragment = document.createDocumentFragment()
  if (isSlideshow.value) {
    const iconLinkTag = document.createElement('link')
    iconLinkTag.setAttribute('rel', 'icon')
    iconLinkTag.setAttribute('href', `/slideshow/favicon.ico`)
    iconLinkTag.setAttribute('sizes', '32x32')
    fragment.appendChild(iconLinkTag)
    const appleIconLinkTag = document.createElement('link')
    appleIconLinkTag.setAttribute('rel', 'apple-touch-icon')
    appleIconLinkTag.setAttribute('href', `/slideshow/apple-touch-icon.png`)
    appleIconLinkTag.setAttribute('sizes', '180x180')
    fragment.appendChild(appleIconLinkTag)
    const manifestLinkTag = document.createElement('link')
    manifestLinkTag.setAttribute('rel', 'manifest')
    manifestLinkTag.setAttribute('href', `/slideshow/site.webmanifest`)
    fragment.appendChild(manifestLinkTag)
    document.head.appendChild(fragment)
    return
  }
  if (wallAttributes.value?.portrait != null && wallAttributes.value?.portrait !== '') {
    const iconLinkTag = document.createElement('link')
    iconLinkTag.setAttribute('rel', 'icon')
    const iconUrl = processedUrl(wallAttributes.value.portrait, {
      width: 64,
      height: 64,
      preset: 'icon',
    })
    iconLinkTag.setAttribute('href', iconUrl)
    fragment.appendChild(iconLinkTag)
    const appleIconLinkTag = document.createElement('link')
    appleIconLinkTag.setAttribute('rel', 'apple-touch-icon')
    const appleIconUrl = processedUrl(wallAttributes.value.portrait, {
      width: 180,
      height: 180,
      preset: 'icon',
    })
    appleIconLinkTag.setAttribute('href', appleIconUrl)
    appleIconLinkTag.setAttribute('sizes', '180x180')
    fragment.appendChild(appleIconLinkTag)
    document.head.appendChild(fragment)
    return
  }
  const iconLinkTag = document.createElement('link')
  iconLinkTag.setAttribute('rel', 'icon')
  iconLinkTag.setAttribute('href', 'https://padlet.net/favicon.ico')
  iconLinkTag.setAttribute('sizes', '32x32')
  fragment.appendChild(iconLinkTag)
  const iconSvgLinkTag = document.createElement('link')
  iconSvgLinkTag.setAttribute('rel', 'icon')
  iconSvgLinkTag.setAttribute('type', 'image/svg+xml')
  iconSvgLinkTag.setAttribute('href', 'https://padlet.net/favicon.svg')
  fragment.appendChild(iconSvgLinkTag)
  const appleIconLinkTag = document.createElement('link')
  appleIconLinkTag.setAttribute('rel', 'apple-touch-icon')
  appleIconLinkTag.setAttribute('href', 'https://padlet.net/apple-touch-icon.png')
  appleIconLinkTag.setAttribute('sizes', '180x180')
  fragment.appendChild(appleIconLinkTag)
  document.head.appendChild(fragment)
}

const insertPrintNotificationElement = (): void => {
  const printNotificationElement = document.createElement('div')
  const appElement = document.getElementById('app')
  if (appElement === null) return
  printNotificationElement.setAttribute('id', 'print-notification')
  printNotificationElement.setAttribute(
    'title',
    __('To print this page properly, go to the "Share" panel and select "Print" under the "Export" section.'),
  )
  document.body.insertBefore(printNotificationElement, appElement)
}

const updateBodyFontAttribute = (): void => {
  const bodyTag = document.body
  if (wallAttributes.value?.font_id == null) return
  bodyTag.setAttribute('data-font', wallAttributes.value.font_id.toString())
}

const updateBodyColorSchemeAttribute = (): void => {
  const bodyTag = document.body
  if (wallAttributes.value?.color_scheme == null) return
  bodyTag.setAttribute('data-color-scheme', wallAttributes.value.color_scheme.toString())
}

const updateDataLayoutAttribute = (): void => {
  const htmlTag = document.documentElement
  if (wallAttributes.value?.viz == null) return
  htmlTag.setAttribute('data-layout', wallAttributes.value.viz.toString())
}

const hydrateFullClientSurface = () => {
  requestAnimationFrame(() => {
    updateTitle()
    initializeFavicon()
    insertPrintNotificationElement()
    const mobileAppVersion = Number(document.body.getAttribute('ww-variant-version'))
    if (!isSlideshow.value && !isGeneratingPDF.value && !(device.app && mobileAppVersion >= 191)) {
      updatePageBackground()
    }
    updateBodyFontAttribute()
    updateBodyColorSchemeAttribute()
    updateDataLayoutAttribute()
  })
}
// #endregion

// #region Life cycle
let removeKeyboardShortcuts = () => {}

onBeforeMount(() => {
  if (isAppUsing('fullClientSurface')) {
    hydrateFullClientSurface()
  }
})

onMounted(async () => {
  if (device.zoomApp) {
    const zoomSdk = await configureZoomApp()
    zoomSdk.expandApp({ action: 'expand' })
  }
  if (isDebugMode || isAppUsing('showCommandPalette')) {
    removeKeyboardShortcuts = tinykeys(document.documentElement as HTMLElement, {
      '$mod+KeyK': commandsStore.showCommandDialog,
    })
  }

  if (getSearchParam('grading_panel_open') === 'true') {
    nextTick(() => {
      useSurfaceGradingPanelStore().showSurfaceGradingPanel()
    })
  }

  if (shouldEnableAnonymousAttribution.value && !amIRegistered.value) {
    setAnonymousUserHeartbeatCookie()
  }
})

onBeforeUnmount(() => {
  if (isDebugMode || isAppUsing('showCommandPalette')) {
    removeKeyboardShortcuts()
  }

  if (shouldEnableAnonymousAttribution.value && !amIRegistered.value) {
    setCookie('ww_auh', String(new Date().getTime()), {
      maxAgeInSeconds: ANONYMOUS_USER_HEARTBEAT_MAX_AGE_COOKIE_IN_SECONDS,
    })
  }
  if (anonymousUserHeartbeatInterval !== null) {
    clearInterval(anonymousUserHeartbeatInterval)
  }
})
// #endregion
</script>

<template>
  <div id="app">
    <!-- #region Slideshow app -->
    <template v-if="isSlideshow">
      <SurfaceOffline v-if="!isOnline" />
      <SurfaceSlideshow />
    </template>
    <!-- #endregion -->

    <!-- #region PDF export app -->
    <template v-else-if="isGeneratingPDF">
      <SurfaceOffline v-if="!isOnline" />
      <SurfaceExportsDocumentStatus />
    </template>
    <!-- #endregion -->

    <!-- #region Surface app -->
    <template v-else>
      <!-- #region Demo/onboarding flow -->
      <template v-if="isDemoPadletLandingStep">
        <SurfaceOffline v-if="!isOnline" />
        <!-- We load surface and the demo padlet landing at the same time, thus we overlay the landing on surface -->
        <SurfaceOnboardingDemoPadletLanding class="absolute inset-0 z-10" />
      </template>
      <!-- #endregion -->

      <SurfaceOffline v-if="!isOnline" />
      <div
        :dir="dir()"
        :class="[
          'flex flex-1',
          hasSidePanelOutsideSurface && {
            'flex-col h-vh100': isSmallerThanTabletPortrait,
            'h-full': !isSmallerThanTabletPortrait,
          },
        ]"
      >
        <SkipToContentLink v-if="!device.app" content-element-selector="#wish-list" />

        <!-- #region Board surface -->
        <SurfaceContainer
          v-if="!isWhiteboard"
          id="surface-container"
          :inert="isDemoPadletLandingStep"
          :class="[
            // We add positive mb here and negative mt on the SurfaceOnboardingGalleryPanelMobile to create black background between
            isSidePanelRounded && {
              'max-h-vh100 h-auto grow': isSmallerThanTabletPortrait,
              'overflow-x-auto overflow-y-hidden': !isSmallerThanTabletPortrait,
              // We treat the surface action bar normally when on demo padlet panel, because we don't support mobile demo padlet panel
              'mb-4 tablet-portrait:mb-0': !isDemoPadletPanel,
            },
          ]"
          :style="
            hasSidePanelOutsideSurface && isSmallerThanTabletPortrait
              ? { height: `calc(var(--vh100) - ${surfaceMobileSidePanelHeight}` }
              : {}
          "
        />
        <!-- #endregion -->

        <!-- #region Sandbox surface -->
        <WhiteboardContainer
          v-else
          id="surface-container"
          :class="[
            'relative',
            // We add positive mb here and negative mt on the SurfaceOnboardingGalleryPanelMobile to create black background between
            hasSidePanelOutsideSurface && {
              'max-h-vh100 h-auto grow mb-4': isSmallerThanTabletPortrait,
              'overflow-x-auto overflow-y-hidden': !isSmallerThanTabletPortrait,
            },
          ]"
          :style="
            hasSidePanelOutsideSurface && isSmallerThanTabletPortrait
              ? { height: `calc(var(--vh100) - ${surfaceMobileSidePanelHeight}` }
              : {}
          "
        />
        <!-- #endregion -->

        <!-- #region Panels out side surface container -->
        <template v-if="xOnboardingPanel">
          <SurfaceOnboardingPanelMobile
            v-if="isSmallerThanTabletPortrait"
            ref="surfaceMobileSidePanel"
            @hook:mounted="handleMobileSidePanelMounted"
          />
          <SurfaceOnboardingPanel v-else data-testid="surfaceOnboardingPanel" />
        </template>

        <Transition :name="isSmallerThanDesktop ? 'bounce-small' : 'bounce'">
          <SurfaceAIChatPanel v-if="xSurfaceAIChatPanel" />
        </Transition>

        <template v-if="xSurfaceGradingPanel">
          <SurfaceGradingPanelMobile
            v-if="isSmallerThanTabletPortrait"
            ref="surfaceMobileSidePanel"
            @hook:mounted="handleMobileSidePanelMounted"
          />
          <SurfaceGradingPanel v-else data-testid="surfaceGradingPanel" />
        </template>
        <!-- #endregion -->
      </div>

      <!-- #region Global components -->
      <SurfaceDragAndDropOverlay v-if="canIPost && !isWhiteboard" />
      <GlobalSnackbar />
      <GlobalMentionAutocomplete />
      <CommandDialog v-if="isAppUsing('showCommandPalette') && xCommandDialog && !isDemoPadletPanelDesktop" />
      <SurfaceTransferWallModal v-if="xTransferWallDialog" />

      <template v-if="shouldEnableAnonymousAttribution && !amIRegistered">
        <SurfaceGuestIdModal v-if="xGuestIdModal" />
        <SurfaceGuestIdDrawer v-if="xGuestIdCard && isSmallerThanTabletPortrait" />
        <SurfaceChangeGuestModal v-if="xChangeGuestModal && isSmallerThanTabletPortrait" />
      </template>
      <!-- #endregion -->
    </template>
    <!-- #endregion -->
  </div>
</template>

<style lang="scss">
html {
  font-size: 16px;
}
</style>
<style lang="scss" scoped>
@import '@@/styles/global';
#app {
  @include global-font-variables;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: -webkit-fill-available;
}

/* Using Vue's transition  */
.bounce-enter-active {
  animation: bounce-in-from-right 0.35s ease-out forwards;
  overflow-x: hidden;
}

.bounce-leave-active {
  animation: slide-out 0.2s ease-in;
  overflow-x: hidden;
}

.bounce-enter,
.bounce-leave-to {
  max-width: 0px;
  min-width: 0px;
}

.bounce-small-enter-active {
  animation: bounce-in-from-right-small 0.35s ease-out forwards;
  overflow-x: hidden;
}

.bounce-small-leave-active {
  animation: slide-out-small 0.2s ease-in;
  overflow-x: hidden;
}

.bounce-small-enter,
.bounce-small-leave-to {
  max-width: 0px;
  min-width: 0px;
}

/* Changing width to simulate bounce effect */
@keyframes bounce-in-from-right {
  0% {
    max-width: 0px;
    min-width: 0px;
  }
  65% {
    max-width: 500px;
    min-width: 500px;
  }
  100% {
    max-width: 480px;
    min-width: 480px;
  }
}

@keyframes slide-out {
  0% {
    max-width: 480px;
    min-width: 480px;
  }
  100% {
    max-width: 0px;
    min-width: 0px;
  }
}

@keyframes bounce-in-from-right-small {
  0% {
    max-width: 0px;
    min-width: 0px;
  }
  65% {
    max-width: 420px;
    min-width: 420px;
  }
  100% {
    max-width: 400px;
    min-width: 400px;
  }
}

@keyframes slide-out-small {
  0% {
    max-width: 400px;
    min-width: 400px;
  }
  100% {
    max-width: 0px;
    min-width: 0px;
  }
}
</style>
