/*
 * @file tracks if user hits print command
 */
import { trackEvent } from '@@/bits/analytics'

const track = (): void => trackEvent('Surface', 'Displayed message to use Padlet print feature')
const trackIfMatch = (mql: MediaQueryListEvent): void => {
  if (mql.matches) {
    track()
  }
}

export function trackPrinting(): void {
  if (window.matchMedia) {
    const mediaQueryList = window.matchMedia('print')
    if (mediaQueryList.addEventListener) {
      mediaQueryList.addEventListener('change', trackIfMatch)
    } else if (mediaQueryList.addListener) {
      // fallback for old browsers (Safari prior to 14)
      // addListener is deprecated for newer browsers
      // https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/addListener
      mediaQueryList.addListener(trackIfMatch)
    }
  }
  window.onbeforeprint = track
}
