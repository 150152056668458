// @file Store for handling mentions.
import { captureFetchException } from '@@/bits/error_tracker'
import { __ } from '@@/bits/intl'
import type { InsertMentionFunction, TiptapMentionEvent } from '@@/bits/mentions'
import { MENTION_SUGGESTIONS_LIMIT } from '@@/bits/mentions'
import { SnackbarNotificationType, WallAccessRight } from '@@/enums'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useSurfaceStore } from '@@/pinia/surface'
import PadletApi from '@@/surface/padlet_api'
import type { Id, UserMentionSuggestion } from '@@/types'
import { MentionEvent } from '@@/vuedirectives/mention_autocomplete'
import { debounce } from 'lodash-es'
import { defineStore, storeToRefs } from 'pinia'
import type Trix from 'trix'
import { computed, ref } from 'vue'

interface MentionSuggestionsFetchParams {
  wallId: number
  wishId?: number
  q?: string
}

async function fetchMentionSuggestions(params: MentionSuggestionsFetchParams): Promise<UserMentionSuggestion[]> {
  try {
    return await PadletApi.MentionSuggestions.fetchMentionSuggestions(params)
  } catch (e) {
    captureFetchException(e, { source: 'fetchMentionSuggestions' })
    return []
  }
}

export const useMentionsStore = defineStore('mentions', () => {
  const { wallId } = storeToRefs(useSurfaceStore())
  const { genericFetchError } = useGlobalSnackbarStore()

  /*
   * State
   */

  const xMentionBox = ref(false)
  const mentionPopoverPosition = ref({ top: 0, left: 0 })
  const activePostBodyEditor = ref<(HTMLElement & { editor: Trix }) | null>(null)
  const insertMentionFunction = ref<InsertMentionFunction | null>(null)

  const postIdToSuggestMentions = ref<Id | undefined>()
  const searchTerm = ref('')
  const mentionSuggestions = ref<UserMentionSuggestion[]>([])
  const isLoadingSuggestions = ref(false)
  const currentHighlightedSuggestionIndex = ref(0)
  const isMentionBoxFocused = ref(false)

  /*
   * Getters
   */

  const currentHighlightedSuggestion = computed<UserMentionSuggestion | undefined>(
    () => mentionSuggestions.value[currentHighlightedSuggestionIndex.value],
  )

  /*
   * Actions
   */

  async function showMentionBox(event: MentionEvent | TiptapMentionEvent): Promise<void> {
    // There's only one mention box at a time
    hideMentionBox()

    if (event instanceof MentionEvent) {
      activePostBodyEditor.value = event.originalEvent.target as HTMLElement & { editor: Trix }
    } else {
      insertMentionFunction.value = event.insertMentionFunction ?? null
    }

    // Show the mention box in loading state first
    isLoadingSuggestions.value = true
    if (event.caretCoordinates != null) {
      mentionPopoverPosition.value = event.caretCoordinates
    }
    xMentionBox.value = true
    currentHighlightedSuggestionIndex.value = 0

    // Fetch suggestions
    postIdToSuggestMentions.value = event.postId
    searchTerm.value = event.searchTerm ?? ''

    const params: MentionSuggestionsFetchParams = {
      wallId: wallId.value,
      wishId: postIdToSuggestMentions.value,
    }
    if (searchTerm.value.length > 0) params.q = searchTerm.value
    mentionSuggestions.value = await fetchMentionSuggestions(params)

    // Flip this boolean so real suggestions are shown
    isLoadingSuggestions.value = false
  }

  function hideMentionBox(): void {
    xMentionBox.value = false
    activePostBodyEditor.value = null
    insertMentionFunction.value = null
    postIdToSuggestMentions.value = undefined
    searchTerm.value = ''
    mentionSuggestions.value = []
    isMentionBoxFocused.value = false
  }

  const debouncedSearchMentionSuggestions = debounce(async () => {
    const params: MentionSuggestionsFetchParams = {
      wallId: wallId.value,
      wishId: postIdToSuggestMentions.value,
    }
    if (searchTerm.value.length > 0) params.q = searchTerm.value
    const fetchedMentionSuggestions = await fetchMentionSuggestions(params)
    mentionSuggestions.value = fetchedMentionSuggestions.slice(0, MENTION_SUGGESTIONS_LIMIT)
    currentHighlightedSuggestionIndex.value = 0
    isLoadingSuggestions.value = false
  }, 100)

  function searchSuggestions(event: { searchTerm?: string }): void {
    searchTerm.value = event.searchTerm ?? ''
    isLoadingSuggestions.value = true
    void debouncedSearchMentionSuggestions()
  }

  function highlightPreviousSuggestion(): void {
    if (currentHighlightedSuggestionIndex.value === 0) {
      currentHighlightedSuggestionIndex.value = mentionSuggestions.value.length - 1
    } else {
      currentHighlightedSuggestionIndex.value = currentHighlightedSuggestionIndex.value - 1
    }
  }

  function highlightNextSuggestion(): void {
    if (currentHighlightedSuggestionIndex.value === mentionSuggestions.value.length - 1) {
      currentHighlightedSuggestionIndex.value = 0
    } else {
      currentHighlightedSuggestionIndex.value = currentHighlightedSuggestionIndex.value + 1
    }
  }

  function setMentionBoxFocus(): void {
    isMentionBoxFocused.value = true
  }

  async function inviteMentionedUser(suggestion: UserMentionSuggestion): Promise<void> {
    try {
      await PadletApi.collaboratorCreate({
        wallId: wallId.value,
        userId: suggestion.attributes.id,
        right: WallAccessRight.Write,
      })
      void useGlobalSnackbarStore().setSnackbar({
        message: __('%{mentioneeDisplayName} invited as writer', {
          mentioneeDisplayName: suggestion.attributes.display_name,
        }),
        notificationType: SnackbarNotificationType.success,
      })
    } catch (e) {
      genericFetchError()
      captureFetchException(e, { source: 'InviteMentionedUser' })
    }
  }

  function handleMentionedUserAccess(suggestion: UserMentionSuggestion): void {
    const hasAccess = suggestion.attributes.has_access ?? true
    if (hasAccess) return
    void useGlobalSnackbarStore().setSnackbar({
      message: __("%{mentioneeDisplayName} doesn't have access to this padlet", {
        mentioneeDisplayName: suggestion.attributes.display_name,
      }),
      notificationType: SnackbarNotificationType.error,
      actionText: __('Invite'),
      actionTextActions: [
        () => {
          void inviteMentionedUser(suggestion)
        },
      ],
    })
  }

  return {
    // State
    xMentionBox,
    mentionPopoverPosition,
    activePostBodyEditor,
    insertMentionFunction,
    searchTerm,
    mentionSuggestions,
    isLoadingSuggestions,
    currentHighlightedSuggestionIndex,
    isMentionBoxFocused,

    // Getters
    currentHighlightedSuggestion,

    // Actions
    showMentionBox,
    hideMentionBox,
    searchSuggestions,
    highlightPreviousSuggestion,
    highlightNextSuggestion,
    setMentionBoxFocus,
    handleMentionedUserAccess,
  }
})
