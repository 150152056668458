// @file Composable to encapsulate presence logic in the surface.
import { useRealtimeStore } from '@@/pinia/surface_realtime'
import { onBeforeUnmount, onMounted } from 'vue'

// Keep in sync with `Presence` Rails service.
const PRESENCE_EXPIRY_IN_SECONDS = 40

export const useSurfacePresence = (): void => {
  const realtimeStore = useRealtimeStore()
  const { absent, present } = realtimeStore

  let intervalId: any = null

  const listener = (): void => {
    if (document.visibilityState === 'visible') {
      present()
    } else {
      absent()
    }
  }

  onMounted((): void => {
    document.addEventListener('visibilitychange', listener)
    document.addEventListener('beforeunload', absent)
    listener()

    // The Presence key-value entry in MemStore has a TTL of intervalInSeconds.
    // Given that the event listener only calls updatePresence on visibility change,
    // if a user is on a wall for > intervalInSeconds (i.e. there is no visibility change),
    // the user will be marked as absent on the backend. This is why the following is required.
    intervalId = setInterval(listener, PRESENCE_EXPIRY_IN_SECONDS * 1000)
  })

  onBeforeUnmount((): void => {
    clearInterval(intervalId)
    document.removeEventListener('visibilitychange', listener)
    absent()
  })
}
