// @file Store to handle drag and drop events globally
import { defineStore } from 'pinia'
import { ref } from 'vue'

export enum DropZone {
  DOCUMENT = 'document',
  POST_COMPOSER = 'post_composer',
  EXPANDED_VIEW = 'expanded_view',
  POST_COMMENT_SECTION = 'post_comment_section',
}

export const useSurfaceDragAndDropEventsStore = defineStore('surfaceDragAndDropEvents', () => {
  /* ---------------------- */
  /* STATE                  */
  /* ---------------------- */

  const isHandlingDragEvent = ref<boolean>(false)
  const activeDropZone = ref<DropZone | null>(null)
  const dropActionMessage = ref<string | null>(null)

  /* ---------------------- */
  /* ACTIONS                */
  /* ---------------------- */

  const startHandlingDragEvent = (): void => {
    isHandlingDragEvent.value = true
  }

  const stopHandlingDragEvent = (): void => {
    isHandlingDragEvent.value = false
  }

  const setActiveDropZone = (dropZone: DropZone | null): void => {
    activeDropZone.value = dropZone
  }

  const setDropActionMessage = (newDropActionMessage: string | null): void => {
    dropActionMessage.value = newDropActionMessage
  }

  return {
    // Getters
    isHandlingDragEvent,
    activeDropZone,
    dropActionMessage,

    // Actions
    startHandlingDragEvent,
    stopHandlingDragEvent,
    setActiveDropZone,
    setDropActionMessage,
  }
})
