// @file Surface posts drag and drop store
import { getVuexStore } from '@@/bits/pinia'
import type { Group } from '@@/bits/surface_posts_grouping_helper'
import { unboundedWatch } from '@@/bits/vue'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceCurrentUserStore } from '@@/pinia/surface_current_user'
import { useSurfaceFilterStore } from '@@/pinia/surface_filter'
import { useSurfaceOnboardingDemoPadletPanelStore } from '@@/pinia/surface_onboarding_demo_padlet_panel_store'
import { useSurfacePermissionsStore } from '@@/pinia/surface_permissions'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import { useSurfacePostSearchStore } from '@@/pinia/surface_post_search'
import { useSurfaceWishArrangementStore } from '@@/pinia/surface_wish_arrangement'
import type { Cid } from '@@/types'
import type { RootState } from '@@/vuexstore/surface/types'
import { GroupByTypes } from '@padlet/arvo'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useSurfacePostsDragAndDropStore = defineStore('surfacePostsDragAndDrop', () => {
  const surfaceVuexStore = getVuexStore<RootState>()
  const surfaceStore = useSurfaceStore()
  const surfacePostsStore = useSurfacePostsStore()
  const surfacePostSearchStore = useSurfacePostSearchStore()
  const surfaceFilterStore = useSurfaceFilterStore()
  const surfaceWishArrangementStore = useSurfaceWishArrangementStore()
  const surfacePermissionsStore = useSurfacePermissionsStore()
  const surfaceCurrentUserStore = useSurfaceCurrentUserStore()
  const surfaceDemoPadletPanelStore = useSurfaceOnboardingDemoPadletPanelStore()

  /*
   * State
   */
  const isAPostDragging = ref(false)
  const postCidBeingDragged = ref<Cid>()
  // Use groupIdUnderPostBeingDragged when you want to change the groupId depending on where the posts is
  const groupIdUnderPostBeingDragged = ref<Group['id']>()
  // Use groupIdOfPostBeingDragged when you do not want the groupId to change when dragging a post
  const groupIdOfPostBeingDragged = ref<Group['id']>()

  // Sync isAPostDragging with Vuex
  void unboundedWatch(isAPostDragging, (isDragging) => {
    void surfaceVuexStore?.dispatch('changeDragging', { isAPostDragging: isDragging })
  })

  /*
   * Getters
   */
  const isDraggingPostInNonManuallySortedWall = computed(() => {
    return isAPostDragging.value && surfaceWishArrangementStore.isSectionedAndNonmanualSorted
  })

  // Returns true if the current user has permission to drag the post
  const canIDragPosts = computed(() => {
    const postBeingDragged =
      postCidBeingDragged.value != null ? surfacePostsStore.postEntitiesByCid[postCidBeingDragged.value] : null
    const wasWrittenByCurrentUser =
      surfaceCurrentUserStore.currentUser?.id != null &&
      postBeingDragged?.author_id === surfaceCurrentUserStore.currentUser.id
    // check if user has permission to drag the post
    if (surfacePermissionsStore.canIModerate || (wasWrittenByCurrentUser && surfacePermissionsStore.canIWrite)) {
      return true
    }
    return false
  })

  // Returns true if post drag and drop should be disabled. Besides user permission, we only allow dragging posts if:
  // - Posts are grouped by section or no grouping
  // - If posts aren't grouped, they must be manually sorted
  // - There is no active search term/filter
  // - The padlet isn't frozen
  const shouldDisableDraggingPost = computed(
    () =>
      !canIDragPosts.value ||
      ![GroupByTypes.None, GroupByTypes.Section].includes(surfaceWishArrangementStore.wishGroupBy) ||
      surfaceWishArrangementStore.isNotSectionedAndNonmanualSorted ||
      surfacePostSearchStore.searchTerm !== '' ||
      surfaceFilterStore.hasSelectedFilters ||
      surfaceStore.isFrozen ||
      surfaceDemoPadletPanelStore.isDemoPadletPanelDesktop,
  )

  /*
   * Getter functions
   */
  const canShowDraggingOverlayOnGroup = (groupId: Group['id']): boolean => {
    return isDraggingPostInNonManuallySortedWall.value && groupIdUnderPostBeingDragged.value === groupId
  }

  return {
    // State
    isAPostDragging,
    postCidBeingDragged,
    groupIdUnderPostBeingDragged,
    groupIdOfPostBeingDragged,

    // Getters
    isDraggingPostInNonManuallySortedWall,
    shouldDisableDraggingPost,

    // Getter functions
    canShowDraggingOverlayOnGroup,
  }
})
