<script setup lang="ts">
import { defineAsyncComponent } from '@@/bits/vue'
import { useMentionsStore } from '@@/pinia/mentions'
import { useSurfaceDraftsStore } from '@@/pinia/surface_drafts'
import { useWindowSizeStore } from '@@/pinia/window_size'
import { storeToRefs } from 'pinia'

const MentionAutocompleteBar = defineAsyncComponent(() => import('@@/vuecomponents/MentionAutocompleteBar.vue'))
const MentionAutocompletePopover = defineAsyncComponent(() => import('@@/vuecomponents/MentionAutocompletePopover.vue'))

const { xMentionBox } = storeToRefs(useMentionsStore())
const { isSmallerThanTabletPortrait } = storeToRefs(useWindowSizeStore())
const { activeDraftCid } = storeToRefs(useSurfaceDraftsStore())
</script>

<template>
  <div v-if="xMentionBox">
    <!-- We display the mentions bar on small screens, where the input field isn't at the bottom of the screen. -->
    <MentionAutocompleteBar
      v-if="
        isSmallerThanTabletPortrait && activeDraftCid // editing on mobile post composer
      "
    />
    <!-- Input is at bottom of screen, for eg the comment edit drawer -->
    <MentionAutocompletePopover v-else />
  </div>
</template>
