// @file Composable to encapsulate fullscreen logic
import { enterFullscreen, exitFullscreen } from '@@/bits/fullscreen'
import { useWindowSizeStore } from '@@/pinia/window_size'
import { storeToRefs } from 'pinia'
import { watch } from 'vue'

export const useFullscreen = (): void => {
  const windowSizeStore = useWindowSizeStore()
  const { isFullscreen } = storeToRefs(windowSizeStore)

  watch(isFullscreen, (isFullscreenEnabled: boolean): void => {
    isFullscreenEnabled ? enterFullscreen() : exitFullscreen()
  })
}
