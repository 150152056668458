// @file Surface realtime store
import { getVuexStore } from '@@/bits/pinia'
import { RootState } from '@@/vuexstore/surface/types'
import { defineStore } from 'pinia'

export const useRealtimeStore = defineStore('realtime', () => {
  const surfaceVuexStore = getVuexStore<RootState>()

  // Actions
  const absent = (): void => {
    surfaceVuexStore?.dispatch('realtime/absent', null, { root: true })
  }

  const present = (): void => {
    surfaceVuexStore?.dispatch('realtime/present', null, { root: true })
  }

  return {
    // Actions
    absent,
    present,
  }
})
